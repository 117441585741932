import React, { Component } from 'react';
import Layout from '../../components/Layout';
import Helmet from "react-helmet";

class Thanks extends Component {
    render() {
        return (
            <Layout newsletter="true" about="true">
                <Helmet>
                    <title>پیام ارسال شد</title>
                    <link rel="canonical" href={`https://filtershekanha.com/contact/thanks/`} />
                </Helmet>
                <div className="row my-5 py-md-5"></div>
                <div className="row justify-content-center">
                    <div className="col-md-9 col-12 my-5 text-center">
                        <h1 className="dir-rtl py-2 c-a">پیام شما با موفقیت ارسال شد</h1>
                    </div>
                    <div className="col-12 my-4"></div>
                    <div className="col-md-9 col-12 text-justify dir-rtl post my-5">
                        <p className="justify c-a"></p>
                        <br />
                    </div>
                </div>
                <div className="row my-4 py-md-4"></div>
                
            </Layout>
        )
    }
}

export default Thanks;
